<template>
  <div class="abouts">
    <TitleHeader msg="首营资料"></TitleHeader>
    <!-- <el-row class="header">
      <el-col :span="3">输入搜索：</el-col>
      <el-col :span="8"
        ><el-input
          placeholder="订单/商品编号,名称"
          v-model="input"
          clearable
        ></el-input
      ></el-col>
      <el-col :span="6"
        ><el-button @click="search" class="search">查询</el-button></el-col
      >
    </el-row> -->

    <el-table
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :data="tableData"
      class="wraper"
      style="width: 100%;"
    >
      <el-table-column
        class="text-center"
        prop="name"
        label="商品名称"
        width="180"
      >
      </el-table-column>
      <el-table-column class="text-center" prop="specification" label="规格" width="180">
      </el-table-column>
      <el-table-column class="text-center" prop="manufacturerName" label="生产企业">
      </el-table-column>
      <el-table-column class="text-center" prop="barCode" label="批号">
      </el-table-column>
      <el-table-column class="text-center" label="首营资料">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click.native="Download(scope.row.goodsId)">查看下载</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      layout="prev, pager, next"
      :hide-on-single-page="false"
      prev-text="上一页"
      next-text="下一页"
      :page-size="10"
      :current-page="currentPage"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import {GetProductPage,DownloadFirstTest} from "api/product.js"
const TitleHeader = () => import("components/common/TitleHeader.vue");
export default {
  name: "FirstInfo",
  data() {
    return {
      current: 1, //当前页
      total:0,
      size: 10, //每页数量
      loading: false,
      name:'',//传给后台的搜索值
      tableData: [],
      parmesData: {}, //初始搜索值传参
    };
  },
  methods: {
     handleCurrentChange(val) {
      this.current = val;
       this.GetProductPageFun();
    },
    search() {
      this.parmesData.name = this.name; //搜索条件
      this.loading = true;
       this.parmesData.current = this.current; //当前页
      this.parmesData.size = this.size; //每页数量
      this.GetProductPageFun();
    },
    GetProductPageFun(){
      this.parmesData.current = this.current; //当前页
      this.parmesData.size = this.size; //每页数量
       //获取商品列表
      GetProductPage(this.parmesData).then(data=>{
        this.tableData=data.data.data.records
        this.total=data.data.data.total
         this.loading = false;
      })
    },

    Download(goodsId){
       console.log('.productId.goodNum',goodsId)
       // 显示loading
       this.$loading({
          fullscreen: true,
          background: '#ffffff45'
        })
         DownloadFirstTest(goodsId).then(res=>{
            if(res.data.code==400){
               this.$message({
                    message:res.data.msg,
                    type: 'warning'
                  });
           }else{
                // 关闭loading
            this.$loading().close();
            let href=res.data.data;
            window.open(href, '_blank', 'toolbar=yes, width=900, height=700')
           }
     
        })
    }
  },
  created() {
    this.GetProductPageFun();
    var lett = this;
    document.onkeydown = function() {
        var key = window.event.keyCode;
        if (key == 13) {
         lett.search()
        }
    }
  },
  components: {
    TitleHeader,
  },
};
</script>
<style>

/* 表格样式 */
.FirstInfo .el-table--border::after,
.el-table--group::after,
.el-table::before {
  background-color: #fff !important;
}
.FirstInfo .el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
.FirstInfo .el-table__fixed-right::before,
.el-table__fixed::before {
  background-color: none;
}
/* th样式 */
.FirstInfo .el-table th {
  background-color: #eee;
  text-align: center;
}
/* td样式 */
.FirstInfo .el-table thead .cell {
  text-align: center;
    font-size: 13px;
    color:#666;
}
.FirstInfo .el-table tbody .cell {
  text-align: center;
    font-size:12px;
    color:#333;
}
    .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #ed3129; 
        color: #fff;
    }
/* 上一页，下一页样式 */
.el-pagination button, .el-pagination span:not([class*=suffix]){
    background: #fff;
    color: #666;
     padding: 4px;
     box-sizing: border-box;
}

/* 每个页码的样式 */
.el-pagination.is-background .el-pager li{
    background: #fff;
    color: #666;
    padding: px;
    box-sizing: border-box;
}
.el-pagination.is-background .el-pager li:not(.disabled):not(.active):hover{
    color:#ed3129; 
    font-size: 14px;
}
</style>
<style lang="less" scoped>
@import "~style/index.less";
  .progressBar {
    height: 20px;
  }
.header {
  padding-left:10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  font-size: 14px;
  .search {
    margin-left: 20px;
  }
}

.wraper {
  margin-top: 30px;
  .el-table thead {
    background: red;
  }
}
.el-pagination {
  padding-top: 40px;
  text-align: center;
}
</style>
